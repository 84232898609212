




























import { computed, defineComponent, reactive } from "@vue/composition-api";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { CreateAndModifyBillingAddressFormState } from "@/core/dataTypes/types";
import NewBillingDetailForm from "@/components/forms/NewBillingDetailsForm/NewBillingDetailsForm.vue";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import { getLanguageStaticRoute } from "@/core/utils/router.util";

export default defineComponent({
  name: "NewBillingAddress",
  components: {
    NewBillingDetailForm,
  },
  setup() {
    const createBillingAddressLoading = computed(
      userFacade.createBillingAddressLoading
    );
    const formValues = reactive<
      Partial<CreateAndModifyBillingAddressFormState>
    >({
      corporation: 0,
      billingName: "",
      country: "",
      city: "",
      zipCode: undefined,
      street: "",
      streetNumber: "",
      streetType: "",
      floor: "",
      door: undefined,
      vatCountry: undefined,
      vatNumber: "",
      invoiceGroupId: "",
      euVatNumber: "",
      thirdCountryVatNumber: "",
      defaultBillingAddress: false,
      hasNotEUTaxNumber: false,
    });

    const submit = () => {
      alertFacade.resetAlert();
      userFacade.createBillingAddress(formValues);
    };
    const prevStep = () => {
      router.push(getLanguageStaticRoute(ERoutes.PROFILE)).catch((err) => err);
    };

    return {
      formValues,
      createBillingAddressLoading,
      prevStep,
      submit,
    };
  },
});
